// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants'

 // PendingOrderReport LIST
export const PendingOrderReportList = createAsyncThunk('PendingOrderList/PendingOrderReportList', async (params) => {
    const response = await axios.post(`${api.api_url}/report/pendingOrderReportListjwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/report/pendingOrderReportList`, params)
      if (response1 && response1.data && response1.data.body) {
      return {
        params,
        PendingOrderReportlist: response1.data.body.PendingOrderList,
        pendindorder_count: response1.data.body.total_count,
        overallTotal_sets:  response1.data.body.overallTotal_sets || 0,
        overallTotal_Pieces: response1.data.body.overallTotal_Pieces || 0,
        dispatchWidget: response1.data.body.dispatchWidget || []
      }
     } else {
      return { 
        PendingOrderReportlist: [],
        pendindorder_count:  0,
        overallTotal_sets: 0,
        overallTotal_Pieces: 0,
        dispatchWidget: []
      }
     } 
  }
  })

  // PendingOrderDropdownReport LIST
export const PendingOrderDropdownList = createAsyncThunk('PendingOrderList/pendingOrderDropdownjwt', async (params) => {
  const response = await axios.post(`${api.api_url}/report/pendingOrderDropdownjwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/report/pendingOrderDropdown`, params)
    if (response1 && response1.data && response1.data.body) {
    return {
      Pending_Customer_OrderList: response1.data.body.Pending_Customer_OrderList,
      Pending_Design_OrderList: response1.data.body.Pending_Design_OrderList,
      Pending_agent_list: response1.data.body.Pending_agent_list     
    }
   } else {
    return {
      Pending_Customer_OrderList: [],
      Pending_Design_OrderList: [],
      Pending_agent_list: []
    }
   } 
}
})

// PendingOrderReport LIST
export const PrintPendingOrderReportList = createAsyncThunk('PendingOrderList/PrintPendingOrderReportList', async (params) => {
  const response = await axios.post(`${api.api_url}/report/pendingOrderReportListjwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/report/pendingOrderReportList`, params)
    if (response1 && response1.data && response1.data.body) {
    return {
      print_pending_order_list: response1.data.body.PendingOrderList || [],
      print_pending_order_company: response1.data.body.Company_Array || [],
      print_pending_order_result: 'true',
      print_overallTotal_sets:  response1.data.body.overallTotal_sets || 0,
      print_overallTotal_Pieces: response1.data.body.overallTotal_Pieces || 0,
      print_dispatchWidget: response1.data.body.dispatchWidget || []
      
    }
   } else {
    return {
      print_pending_order_list: [],
      print_pending_order_company: [],
      print_pending_order_result: 'false',
      print_overallTotal_sets:  response1.data.body.overallTotal_sets || 0,
      print_overallTotal_Pieces: response1.data.body.overallTotal_Pieces || 0,
      print_dispatchWidget: response1.data.body.dispatchWidget || []
    }
 
   } 
}
})

export const getBrandList = createAsyncThunk('FinishedGoodList/brandFilterListJwt', async params => {
  try {
  const response = await axios.post(`${api.api_url}/common/brandFilterListJwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params['jwtToken'] = response.data.body.token
   const response1 = await axios.post(`${api.api_url}/common/brandFilterList`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      all_Brand_List: response1.data.body && response1.data.body.All_Brand_Array ? response1.data.body.All_Brand_Array : [],
      brand_List_Message:response1.data.body.message
    }
   } else {
    return {
      all_Brand_List: response1.data.body ? response1.data.body.All_Brand_Array : [],
      brand_List_Message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
    }
   }  
  } else {
    return {
      all_Brand_List: response.data.body ? response.data.body.All_Brand_Array : [],
      brand_List_Message: response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
    }
   }
  } catch (error) {
    return {
      all_Brand_List: [],
      brand_List_Message:'Server not reachable.Please try again later'
    }
  }
})

export const PendingOrder = createSlice({
  name: 'PendingOrderList',
  initialState: { 
    params: {},
    statusFlag:0,
    message:'', 
    PendingOrderReportlist:[],     
    pendindorder_count: 0,
    design_dropdown_list: [],
    customer_dropdown_list: [],
    Pending_Customer_OrderList: [],
    Pending_Design_OrderList: [],
    print_pending_order_list: [],
    print_pending_order_company: [],
    print_pending_order_result:'',
    Pending_agent_list: [],
    overallTotal_sets: 0,
    overallTotal_Pieces: 0,
    dispatchWidget: [],
    print_overallTotal_sets: 0,
    print_overallTotal_Pieces: 0,
    print_dispatchWidget: [],
    all_Brand_List: [],
    brand_List_Message:''
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
    },
    clearstoreData: (state, action) => {
      state.PendingOrderReportlist = action.payload.PendingOrderReportlist
      state.pendindorder_count = action.payload.pendindorder_count
      state.print_pending_order_list = action.payload.print_pending_order_list
      state.print_pending_order_company = action.payload.print_pending_order_company
      state.print_pending_order_result = action.payload.print_pending_order_result
      state.print_overallTotal_sets = action.payload.print_overallTotal_sets
      state.print_overallTotal_Pieces = action.payload.print_overallTotal_Pieces
      state.print_dispatchWidget = action.payload.print_dispatchWidget
      state.overallTotal_sets = action.payload.overallTotal_sets
      state.overallTotal_Pieces = action.payload.overallTotal_Pieces
      state.dispatchWidget = action.payload.dispatchWidget
    }
  },
  extraReducers: builder => {
    builder.addCase(PendingOrderReportList.fulfilled, (state, action) => {
      state.PendingOrderReportlist = action.payload.PendingOrderReportlist
      state.pendindorder_count = action.payload.pendindorder_count
      state.overallTotal_sets = action.payload.overallTotal_sets
      state.overallTotal_Pieces = action.payload.overallTotal_Pieces
      state.dispatchWidget = action.payload.dispatchWidget
    })
    .addCase(PendingOrderDropdownList.fulfilled, (state, action) => {
      state.Pending_Customer_OrderList = action.payload.Pending_Customer_OrderList
      state.Pending_Design_OrderList = action.payload.Pending_Design_OrderList
      state.Pending_agent_list = action.payload.Pending_agent_list
    }) 
    .addCase(PrintPendingOrderReportList.fulfilled, (state, action) => {
      state.print_pending_order_list = action.payload.print_pending_order_list
      state.print_pending_order_company = action.payload.print_pending_order_company
      state.print_pending_order_result = action.payload.print_pending_order_result
      state.print_overallTotal_sets = action.payload.print_overallTotal_sets
      state.print_overallTotal_Pieces = action.payload.print_overallTotal_Pieces
      state.print_dispatchWidget = action.payload.print_dispatchWidget
    })
    .addCase(getBrandList.fulfilled, (state, action) => {
      state.brand_List_Message = action.payload.brand_List_Message
      state.all_Brand_List = action.payload.all_Brand_List
    })
    
    
  }
})
export const {
  handleStatusFlag, clearstoreData
} = PendingOrder.actions
export default PendingOrder.reducer

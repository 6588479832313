// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants'

//
export const getMasterData = createAsyncThunk('OrderUserStateMapping/getMasterDataJwt', async params => {
  try {
  const response = await axios.post(`${api.api_url}/master/orderusermapping/getMasterDataJwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params['jwtToken'] = response.data.body.token
   const response1 = await axios.post(`${api.api_url}/master/orderusermapping/getMasterData`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      User_Array:response1.data.body && response1.data.body.UserArray ? response1.data.body.UserArray : [],
      State_Array:response1.data.body && response1.data.body.StateArray ? response1.data.body.StateArray : []
    }
   } else {
    return {
      User_Array:response1.data.body ? response1.data.body.UserArray : [],
      State_Array:response1.data.body ? response1.data.body.StateArray : []
    }
   }  
  } else {
    return {
      User_Array:response.data.body ? response.data.body.UserArray : [],
      State_Array:response.data.body ? response.data.body.StateArray : []
    }
   }
  } catch (error) {
    return {
      User_Array: [],
      State_Array:[]
    }
  }
})

//saveData Api
export const saveData = createAsyncThunk('OrderUserStateMapping/saveData', async params => {
  try {
  const response = await axios.post(`${api.api_url}/master/orderusermapping/saveUserStateMappingJwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params['jwtToken'] = response.data.body.token
   const response1 = await axios.post(`${api.api_url}/master/orderusermapping/saveUserStateMapping`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      statusFlag:response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
      message:response1.data.body.message
    }
   } else {
    return {
      statusFlag:response1.data.body ? response1.data.body.statusFlag : 2,
      message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
    }
   }  
  } else {
    return {
      statusFlag:response.data.body ? response.data.body.statusFlag : 2,
      message: response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
    }
   }
  } catch (error) {
    return {
      statusFlag: 2,
      message:'Server not reachable.Please try again later'
    }
  }
})

// Itemgroup LIST

export const getOderUserStateMappingList = createAsyncThunk('OrderUserStateMapping/getUserStateMappingListJwt', async params => {
  try {
  const response = await axios.post(`${api.api_url}/master/orderusermapping/getUserStateMappingListJwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params['jwtToken'] = response.data.body.token
   const response1 = await axios.post(`${api.api_url}/master/orderusermapping/getUserStateMappingList`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      User_State_List:response1.data.body && response1.data.body.UserList ? response1.data.body.UserList : [],
      User_State_Total: response1.data.body && response1.data.body.TotalUser ? response1.data.body.TotalUser : 0
    }
   } else {
    return {
      User_State_List:response1.data.body ? response1.data.body.UserList : [],
      User_State_Total:response1.data.body ? response1.data.body.TotalUser : 0
    }
   }  
  } else {
    return {
      User_State_List:response.data.body ? response.data.body.UserList : [],
      User_State_Total:response.data.body ? response.data.body.TotalUser : 0
    }
   }
  } catch (error) {
    return {
      User_State_List: [],
      User_State_Total: 0
    }
  }
})


export const editOrderUserMappingData = createAsyncThunk('OrderUserStateMapping/editOrderUserMappingData', async params => {
  try {
  const response = await axios.post(`${api.api_url}/master/orderusermapping/editOrderUserMappingDataJwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params['jwtToken'] = response.data.body.token
   const response1 = await axios.post(`${api.api_url}/master/orderusermapping/editOrderUserMappingData`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      Edit_User_State_List:response1.data.body && response1.data.body.EditUserStateMapping ? response1.data.body.EditUserStateMapping : []
    }
   } else {
    return {
      Edit_User_State_List:response1.data.body ? response1.data.body.EditUserStateMapping : []
    }
   }  
  } else {
    return {
      Edit_User_State_List:response.data.body ? response.data.body.EditUserStateMapping : []
    }
   }
  } catch (error) {
    return {
      Edit_User_State_List: []
    }
  }
})


export const deleteData = createAsyncThunk('OrderUserStateMapping/deleteData', async params => {
  try {
  const response = await axios.post(`${api.api_url}/master/orderusermapping/deleteOrderUserMappingJwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params['jwtToken'] = response.data.body.token
   const response1 = await axios.post(`${api.api_url}/master/orderusermapping/deleteOrderUserMapping`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      statusFlag:response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
      message:response1.data.body.message
    }
   } else {
    return {
      statusFlag:response1.data.body ? response1.data.body.statusFlag : 2,
      message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
    }
   }  
  } else {
    return {
      statusFlag:response.data.body ? response.data.body.statusFlag : 2,
      message: response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
    }
   }
  } catch (error) {
    return {
      statusFlag: 2,
      message:'Server not reachable.Please try again later'
    }
  }
})

export const OrderUserStateMappingSlice = createSlice({
  name: 'OrderUserStateMapping',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    Itemgrouplist:[],
    statuslist:[],
    statusFlag:0,
    statuscode:0,
    User_State_List: [],
    User_State_Total: 0,
    User_Array: [],
    State_Array:[],
    Edit_User_State_List: []
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
    },
    clearStoreData: (state, action) => {
      state.User_Array = action.payload.User_Array
      state.State_Array = action.payload.State_Array
      state.User_State_List = action.payload.User_State_List
      state.User_State_Total = action.payload.User_State_Total    
      state.Edit_User_State_List = action.payload.Edit_User_State_List
    }    
  },
  extraReducers: builder => {
    builder.addCase(getMasterData.fulfilled, (state, action) => {
      state.User_Array = action.payload.User_Array
      state.State_Array = action.payload.State_Array
    }).addCase(getOderUserStateMappingList.fulfilled, (state, action) => {
      state.User_State_List = action.payload.User_State_List
      state.User_State_Total = action.payload.User_State_Total   
    }).addCase(saveData.fulfilled, (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.message = action.payload.message
    })
    .addCase(deleteData.fulfilled, (state, action) => {
      state.message = action.payload.message
     state.statusFlag = action.payload.statusFlag
    })
    .addCase(editOrderUserMappingData.fulfilled, (state, action) => {
      state.Edit_User_State_List = action.payload.Edit_User_State_List
    })
  }
})
export const {
  handleStatusFlag, clearStoreData
} = OrderUserStateMappingSlice.actions
export default OrderUserStateMappingSlice.reducer

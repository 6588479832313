// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants'

// Get FG data 
export const GetCSList = createAsyncThunk('CurrentStockData/getCurrentStockJwt', async (params) => {
  const response = await axios.post(`${api.api_url}/dispatch/getCurrentStockJwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params['jwtToken'] = response.data.body.token
    const response1 = await axios.post(`${api.api_url}/dispatch/getCurrentStock`, params)
    if (response1 && response1.data && response1.data.body) {
     return {
      CurrentStockwidget: response1.data.body.CurrentStockwidget
     }
    } 
   } else {
      return {
        CurrentStockwidget: []
      }
    }
})
  // Master List
  export const MasterReportlist = createAsyncThunk('CurrentStockData/MasterReportlist', async (params) => {
    const response = await axios.post(`${api.api_url}/orderreport/reportMasterListjwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/orderreport/reportMasterList`, params)
      if (response1 && response1.data && response1.data.body) { 
      return { 
        Customer_Lists: response1.data.body.Customer_Lists,
        Design_Lists: response1.data.body.Design_Lists,
        Color_Lists: response1.data.body.Color_Lists,
        Size_Lists: response1.data.body.Size_Lists,
        IronMachineList: response1.data.body.ironmachineArray
      }
     } else {
     } 
  }
  })

  //Print Dispatch
  export const PrintCSlist = createAsyncThunk('CurrentStockData/printCurrentStocklist', async (params) => {
    const response = await axios.post(`${api.api_url}/dispatch/getCurrentStockJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/dispatch/getCurrentStock`, params)
      if (response1 && response1.data && response1.data.body) { 
      return { 
        print_CS_list: response1.data.body.CurrentStockArray || [],
        print_CS_company: response1.data.body.Company_Array || [],
        print_result: 'true',
        Print_CS_widget: response1.data.body.CurrentStockwidget || []
      }
     } else {
      return { 
        print_CS_list: [],
        print_CS_company: [],
        print_result: 'false',
        Print_CS_widget: []
      }
     } 
  }
  })
  // get current Stock list based on itemcode
  export const GetCurrentStockList = createAsyncThunk('CurrentStockData/GetCurrentStockList', async (params) => {
    const response = await axios.post(`${api.api_url}/dispatch/getAllStockJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/dispatch/getAllStock`, params)
      if (response1 && response1.data && response1.data.body) {
       return {
        CurrentStockArray:response1.data.body.Stock_Array,
        CurrentStock_total: response1.data.body.Stock_Total || 0
       }
      } 
     } else {
        return {
          CurrentStockArray:[],
          CurrentStock_total: 0
        }
      }
  })
   // get pending Stock list based on itemcode
   export const GetPendingStockList = createAsyncThunk('CurrentStockData/GetPendingStockList', async (params) => {
    const response = await axios.post(`${api.api_url}/dispatch/getAllStockJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/dispatch/getAllStock`, params)
      if (response1 && response1.data && response1.data.body) {
       return {
        PendingStockArray:response1.data.body.Stock_Array,
        PendingStock_total: response1.data.body.Stock_Total || 0
       }
      } 
     } else {
        return {
          PendingStockArray:[],
          PendingStock_total: 0
        }
      }
  })
  // get required Stock list based on itemcode
  export const GetRequiredStockList = createAsyncThunk('CurrentStockData/GetRequiredStockList', async (params) => {
    const response = await axios.post(`${api.api_url}/dispatch/getAllStockJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/dispatch/getAllStock`, params)
      if (response1 && response1.data && response1.data.body) {
       return {
        RequiredStockArray:response1.data.body.Stock_Array,
        RequiredStock_total: response1.data.body.Stock_Total || 0
       }
      } 
     } else {
        return {
          RequiredStockArray:[],
          RequiredStock_total: 0
        }
      }
  })

  // get excess Stock list based on itemcode
  export const GetExcessStockList = createAsyncThunk('CurrentStockData/GetExcessStockList', async (params) => {
    const response = await axios.post(`${api.api_url}/dispatch/getAllStockJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/dispatch/getAllStock`, params)
      if (response1 && response1.data && response1.data.body) {
       return {
        ExcessStockArray:response1.data.body.Stock_Array,
        ExcessStock_total: response1.data.body.Stock_Total || 0
       }
      } 
     } else {
        return {
          ExcessStockArray:[],
          ExcessStock_total: 0
        }
      }
  })

  // get pending Stock for order based on itemcode
  export const GetPendingStockForOrder = createAsyncThunk('CurrentStockData/GetPendingStockForOrder', async (params) => {
    const response = await axios.post(`${api.api_url}/dispatch/getPendingStockForOrderJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/dispatch/getPendingStockForOrder`, params)
      if (response1 && response1.data && response1.data.body) {
       return {
        Pending_Stock_Order:response1.data.body.Pending_Stock
       }
      } 
     } else {
        return {
          Pending_Stock_Order:[]
        }
      }
  })
  
  export const getBrandList = createAsyncThunk('CurrentStockData/brandFilterListJwt', async params => {
    try {
    const response = await axios.post(`${api.api_url}/common/brandFilterListJwt`, params)
     if (response && response.data && response.data.body && response.data.body.token) {
     params['jwtToken'] = response.data.body.token
     const response1 = await axios.post(`${api.api_url}/common/brandFilterList`, params)
     if (response1 && response1.data && response1.data.body) {
      return {
        brand_List:response1.data.body && response1.data.body.BrandArray ? response1.data.body.BrandArray : [],
        brand_List_Message:response1.data.body.message
      }
     } else {
      return {
        brand_List:response1.data.body ? response1.data.body.BrandArray : [],
        brand_List_Message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
      }
     }  
    } else {
      return {
        brand_List:response.data.body ? response.data.body.BrandArray : [],
        brand_List_Message: response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
      }
     }
    } catch (error) {
      return {
        brand_List: [],
        brand_List_Message:'Server not reachable.Please try again later'
      }
    }
  })

export const CurrentStock = createSlice({
  name: 'CurrentStockData',
  initialState: { 
    params: {},
    statusFlag:0,
    message:'',
    ItemList:[],
    CurrentStockArray:[],
    Design_Lists:[],
    IronMachineList:[],
    CurrentStock_total:0,
    print_CS_list: [],
    print_CS_company: [],
    print_result: '',
    Print_CS_widget: [],
    CurrentStockwidget: [],
    Color_Lists: [],
    PendingStockArray:[],
    PendingStock_total: 0,
    RequiredStockArray:[],
    RequiredStock_total: 0,
    ExcessStockArray:[],
    ExcessStock_total: 0,
    Pending_Stock_Order: [],
    brand_List: [],
    brand_List_Message:''
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
    },
    clearstoreData: (state, action) => {
      state.CurrentStockArray = action.payload.CurrentStockArray
      state.CurrentStockwidget = action.payload.CurrentStockwidget
      state.print_CS_list = action.payload.print_CS_list
      state.print_result = action.payload.print_result
      state.print_CS_company = action.payload.print_CS_company  
      state.Print_CS_widget = action.payload.Print_CS_widget 
      state.PendingStockArray = action.payload.PendingStockArray
      state.PendingStock_total = action.payload.PendingStock_total
      state.RequiredStockArray = action.payload.RequiredStockArray
      state.RequiredStock_total = action.payload.RequiredStock_total
      state.ExcessStockArray = action.payload.ExcessStockArray
      state.ExcessStock_total = action.payload.ExcessStock_total
      state.Pending_Stock_Order = action.payload.Pending_Stock_Order
    }
  },
  extraReducers: builder => {
    builder.addCase(GetCSList.fulfilled, (state, action) => {
      state.CurrentStockwidget = action.payload.CurrentStockwidget
    }).addCase(MasterReportlist.fulfilled, (state, action) => {
      state.Design_Lists = action.payload.Design_Lists
      state.IronMachineList = action.payload.IronMachineList
      state.Color_Lists = action.payload.Color_Lists
    }).addCase(PrintCSlist.fulfilled, (state, action) => { 
      state.print_CS_list = action.payload.print_CS_list
      state.print_CS_company = action.payload.print_CS_company
      state.print_result = action.payload.print_result
      state.Print_CS_widget = action.payload.Print_CS_widget
    }).addCase(GetCurrentStockList.fulfilled, (state, action) => {
      state.CurrentStockArray = action.payload.CurrentStockArray
      state.CurrentStock_total = action.payload.CurrentStock_total
    }).addCase(GetPendingStockList.fulfilled, (state, action) => {
      state.PendingStockArray = action.payload.PendingStockArray
      state.PendingStock_total = action.payload.PendingStock_total
    }).addCase(GetRequiredStockList.fulfilled, (state, action) => {
      state.RequiredStockArray = action.payload.RequiredStockArray
      state.RequiredStock_total = action.payload.RequiredStock_total
    }).addCase(GetExcessStockList.fulfilled, (state, action) => {
      state.ExcessStockArray = action.payload.ExcessStockArray
      state.ExcessStock_total = action.payload.ExcessStock_total
    }).addCase(GetPendingStockForOrder.fulfilled, (state, action) => {
      state.Pending_Stock_Order = action.payload.Pending_Stock_Order
    }).addCase(getBrandList.fulfilled, (state, action) => {
      state.brand_List = action.payload.brand_List
      state.brand_List_Message = action.payload.brand_List_Message
    })
  }
})
export const {
  handleStatusFlag, clearstoreData
} = CurrentStock.actions
export default CurrentStock.reducer

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants' 

//  GET Common LIST
export const commonData = createAsyncThunk('EmployeeDetails/commonData', async (params) => {
  const response = await axios.post(`${api.api_url}/common/fetchcommonDataJwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/common/fetchcommonData`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      params,
      BloodGroupArray: response1.data.body.BloodGroupArray,
      EmployeecateryArray: response1.data.body.EmployeecateryArray,
      GenderArray: response1.data.body.GenderArray,
      MaritalArray: response1.data.body.MaritalArray,
      StateArray: response1.data.body.StateArray,
      DesignationArray: response1.data.body.DesignationArray,
      DepartmentArray: response1.data.body.DepartmentArray,
      AllDesignationArray: response1.data.body.allDesignationArray,
      AllDepartmentArray: response1.data.body.allDepartmentArray,
      AllEmployeecateryArray: response1.data.body.allemployeecateryArray,
      EmployeeTypeArray: response1.data.body.EmployeeTypeArray, 
      AllEmployeeTypeArray: response1.data.body.AllEmployeeTypeArray
    }
   } else {
   } 
}
})

//savePersonalData Api
export const savePersonalData = createAsyncThunk('EmployeeDetails/savePersonalData', async params => {
  try {
  const response = await axios.post(`${api.api_url}/employee/savePersonalDetailsjwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/employee/savePersonalDetails`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      errorstatus: response1.data.body && response1.data.body.errorstatus ? response1.data.body.errorstatus : 2,
      EmployeeCode:response1.data.body.employee_id,
      message:response1.data.body.message
    }
   }  else {
    return {
      errorstatus:response1.data.body ? response1.data.body.errorstatus : 2,
      EmployeeCode:response1.data.body ? response1.data.body.employee_id : 0,
      message: response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
    }
   } 
  } else {
    return {
      errorstatus:response.data.body ? response.data.body.errorstatus : 2,
      EmployeeCode:response.data.body ? response.data.body.employee_id : 0,
      message: response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
    }
   }
  } catch (error) {
    return {
      errorstatus: 2,
      EmployeeCode: 0,
      message: 'Server not reachable.Please try again later'
    }    
  }
})

//saveContactData Api
export const saveContactData = createAsyncThunk('EmployeeDetails/saveContactData', async params => {
  try {
  const response = await axios.post(`${api.api_url}/employee/saveContactDetailsjwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/employee/saveContactDetails`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      message:response1.data.body.message
    }
   } else {
    return {
      message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
    }
   } 
  } else {
    return {
      message:response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
    }
   }
  } catch (error) {
    return {
      message:'Server not reachable.Please try again later'
    }
  }
})

//saveBankData Api
export const saveBankData = createAsyncThunk('EmployeeDetails/saveBankData', async params => {
  try {
  const response = await axios.post(`${api.api_url}/employee/SaveBankDetailsjwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/employee/SaveBankDetails`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      message:response1.data.body.message
    }
   } else {
    return {
      message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
    }
   } 
  } else {
    return {
      message:response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
    }
   }
  } catch (error) {
    return {
      message:'Server not reachable.Please try again later'
    }
  }
})

//saveHrData Api
export const saveHRData = createAsyncThunk('EmployeeDetails/saveHRData', async params => {
  try {
  const response = await axios.post(`${api.api_url}/employee/SaveHrDetailsjwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/employee/SaveHrDetails`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      statusFlag:response1.data.body && response1.data.body.finalFlag ? response1.data.body.finalFlag : 2,
      message:response1.data.body.message
    }
   } else {
    return {
      statusFlag:response1.data.body ? response1.data.body.finalFlag : 2,
      message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
    }
   } 
  } else {
    return {
      statusFlag:response.data.body ? response.data.body.finalFlag : 2,
      message: response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
    }
   }
  } catch (error) {
    return {
      statusFlag: 2,
      message:'Server not reachable.Please try again later'
    }
  }
})

// employee LIST
export const employeelist = createAsyncThunk('EmployeeDetails/employeelist', async params => {
  try {
    const response = await axios.post(`${api.api_url}/employee/employeeListjwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/employee/employeeList`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          params,
          employeelist: response1.data.body && response1.data.body.EmployeeArray ? response1.data.body.EmployeeArray : [],
          TotalEmployee: response1.data.body && response1.data.body.TotalEmployee ? response1.data.body.TotalEmployee : 0
        }
      } else {
        return {
          params,
          employeelist: response1.data.body ? response1.data.body.EmployeeArray : [],
          TotalEmployee: response1.data.body ? response1.data.body.TotalEmployee : 0
        }
      }
    } else {
      return {
        params,
        employeelist: response.data.body ? response.data.body.EmployeeArray : [],
        TotalEmployee: response.data.body ? response.data.body.TotalEmployee : 0
      }
    }
  } catch (error) {
    return {
      params,
      employeelist: [],
      TotalEmployee: 0
    }
  }
})


//edit Employee 
export const editData = createAsyncThunk('EmployeeDetails/editData', async (params) => {
  const response = await axios.post(`${api.api_url}/employee/editEmployeejwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/employee/editEmployee`, params)
    if (response1 && response1.data && response1.data.body) {
    return {
      params,
      PersonalDetails: response1.data.body.PersonalArray,
      ContactDetails: response1.data.body.ContactArray,
      BankDetails: response1.data.body.BankArray,
      HrDetails: response1.data.body.HrArray
    }
   } else {
   } 
}
})

//deleteData Api
export const deleteData = createAsyncThunk('EmployeeDetails/deleteData', async params => { 
  const response = await axios.post(`${api.api_url}/employee/deleteEmployeejwt`, params)  
  if (response && response.data && response.data.body && response.data.body.token) { 
    params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/employee/deleteEmployee`, params)
   if (response1 && response1.data && response1.data.body) { 
    return { 
      statusFlag:response1.data.body.statusFlag, 
      message:response1.data.body.message
    }
   }
  } else { 
    return {  
      statusFlag:response.data.statusFlag,
      message:response.data.message
    }
   }
})

export const EmployeeDetailsSlice = createSlice({
    name: 'EmployeeDetails',
    initialState: {
      data: [],
      message:'',
      statuslist:[],
      params: {},
      statusFlag:0,
      EmployeeCode:0,
      errorstatus:0,
      BloodGroupArray:[],
      EmployeecateryArray:[],
      GenderArray:[],
      MaritalArray:[],
      StateArray:[],
      DesignationArray:[],
      DepartmentArray:[],
      employeelist:[],
      TotalEmployee:0,
      PersonalDetails:[],
      ContactDetails:[],
      BankDetails:[],
      HrDetails:[],
      AllDesignationArray:[],
      AllDepartmentArray:[],
      AllEmployeecateryArray:[],
      EmployeeTypeArray:[],
      AllEmployeeTypeArray:[]
    },     
    reducers: {
      handleStatusFlag: (state, action) => {
        state.statusFlag = action.payload.statusFlag
      },
      handleErrorFlag: (state, action) => {
        state.errorstatus = action.payload.errorstatus
      },
      clearstoreData: (state, action) => {
        state.PersonalDetails = action.payload.PersonalDetails
        state.ContactDetails = action.payload.ContactDetails
        state.BankDetails = action.payload.BankDetails
        state.HrDetails = action.payload.HrDetails
      }
    },
    extraReducers: builder => {
      builder.addCase(commonData.fulfilled, (state, action) => {
        state.BloodGroupArray = action.payload.BloodGroupArray
        state.EmployeecateryArray = action.payload.EmployeecateryArray
        state.GenderArray = action.payload.GenderArray
        state.MaritalArray = action.payload.MaritalArray
        state.StateArray = action.payload.StateArray
        state.DesignationArray = action.payload.DesignationArray
        state.DepartmentArray = action.payload.DepartmentArray
        state.AllDesignationArray = action.payload.AllDesignationArray
        state.AllDepartmentArray = action.payload.AllDepartmentArray
        state.AllEmployeecateryArray = action.payload.AllEmployeecateryArray
        state.params = action.payload.params
        state.EmployeeTypeArray = action.payload.EmployeeTypeArray
        state.AllEmployeeTypeArray = action.payload.AllEmployeeTypeArray
      })
      .addCase(savePersonalData.fulfilled, (state, action) => {
        state.errorstatus = action.payload.errorstatus
        state.EmployeeCode = action.payload.EmployeeCode
        state.message = action.payload.message
      })
      .addCase(saveContactData.fulfilled, (state, action) => {
        state.message = action.payload.message
      })
      .addCase(saveBankData.fulfilled, (state, action) => {
        state.message = action.payload.message
      })
      .addCase(saveHRData.fulfilled, (state, action) => {
        state.statusFlag = action.payload.statusFlag
        state.message = action.payload.message
      })
      .addCase(employeelist.fulfilled, (state, action) => {
        state.employeelist = action.payload.employeelist
        state.TotalEmployee = action.payload.TotalEmployee
        state.params = action.payload.params
      })
      .addCase(editData.fulfilled, (state, action) => {
        state.PersonalDetails = action.payload.PersonalDetails
        state.ContactDetails = action.payload.ContactDetails
        state.BankDetails = action.payload.BankDetails
        state.HrDetails = action.payload.HrDetails
        state.params = action.payload.params
      })
      .addCase(deleteData.fulfilled, (state, action) => {
        state.message = action.payload.message
        state.statusFlag = action.payload.statusFlag
      })
    }
  })

  export const {
    handleStatusFlag, handleErrorFlag, clearstoreData
  } = EmployeeDetailsSlice.actions
  
export default EmployeeDetailsSlice.reducer


// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants'


export const getBrandList = createAsyncThunk('ItemgroupMaster/brandFilterListJwt', async params => {
  try {
  const response = await axios.post(`${api.api_url}/common/brandFilterListJwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params['jwtToken'] = response.data.body.token
   const response1 = await axios.post(`${api.api_url}/common/brandFilterList`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      brand_List:response1.data.body && response1.data.body.BrandArray ? response1.data.body.BrandArray : [],
      all_Brand_List: response1.data.body && response1.data.body.All_Brand_Array ? response1.data.body.All_Brand_Array : [],
      brand_List_Message:response1.data.body.message
    }
   } else {
    return {
      brand_List:response1.data.body ? response1.data.body.BrandArray : [],
      all_Brand_List: response1.data.body ? response1.data.body.All_Brand_Array : [],
      brand_List_Message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
    }
   }  
  } else {
    return {
      brand_List:response.data.body ? response.data.body.BrandArray : [],
      all_Brand_List:response.data.body ? response.data.body.all_Brand_List : [],
      brand_List_Message: response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
    }
   }
  } catch (error) {
    return {
      brand_List: [],
      all_Brand_List: [],
      brand_List_Message:'Server not reachable.Please try again later'
    }
  }
})

// Itemgroup LIST

export const Itemgrouplist = createAsyncThunk('ItemgroupMaster/Itemgrouplist', async params => {
  try {
  const response = await axios.post(`${api.api_url}/master/itemgroup/itemgroupListjwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/master/itemgroup/itemgroupList`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      params,
      Itemgrouplist:response1.data.body && response1.data.body.ItemgroupArray ? response1.data.body.ItemgroupArray : [],
      TotalItemGroup:response1.data.body && response1.data.body.TotalItemGroup ? response1.data.body.TotalItemGroup : 0,
      brandWiseItemList: response1.data.body && response1.data.body.brandWiseItemList ? response1.data.body.brandWiseItemList : []
    }
   } else {
    return {
      params,
      Itemgrouplist:response1.data.body ? response1.data.body.ItemgroupArray : [],
      TotalItemGroup:response1.data.body ? response1.data.body.TotalItemGroup : 0,
      brandWiseItemList: response1.data.body ? response1.data.body.brandWiseItemList : []
    }
   }  
  } else {
    return {
      params,
      Itemgrouplist:response.data.body ? response.data.body.ItemgroupArray : [],
      TotalItemGroup: response.data.body ? response.data.body.TotalItemGroup : 0,
      brandWiseItemList: response.data.body ? response.data.body.brandWiseItemList : []
    }
   }
  } catch (error) {
    return {
      params,
      Itemgrouplist: [],
      TotalItemGroup:0,
      brandWiseItemList: []
    }
  }
})
//saveData Api
export const saveData = createAsyncThunk('ItemgroupMaster/saveData', async params => {
  try {
  const response = await axios.post(`${api.api_url}/master/itemgroup/saveItemgroupjwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/master/itemgroup/saveItemgroup`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      statusFlag:response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
      message:response1.data.body.message
    }
   } else {
    return {
      statusFlag:response1.data.body ? response1.data.body.statusFlag : 2,
      message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
    }
   }  
  } else {
    return {
      statusFlag:response.data.body ? response.data.body.statusFlag : 2,
      message: response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
    }
   }
  } catch (error) {
    return {
      statusFlag: 2,
      message:'Server not reachable.Please try again later'
    }
  }
})

//deleteData Api
export const deleteData = createAsyncThunk('ItemgroupMaster/deleteData', async params => { 
  const response = await axios.post(`${api.api_url}/master/itemgroup/deleteItemgroupjwt`, params)  
  if (response && response.data && response.data.body && response.data.body.token) { 
    params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/master/itemgroup/deleteItemgroup`, params)
   if (response1 && response1.data && response1.data.body) { 
    return { 
      statusFlag:response1.data.body.statusFlag, 
      message:response1.data.body.message
    }
   }
  } else { 
    return {  
      statusFlag:response.data.statusFlag,
      message:response.data.message
    }
   }
})
export const ItemgroupMasterSlice = createSlice({
  name: 'ItemgroupMaster',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    Itemgrouplist:[],
    TotalItemGroup:0,
    statuslist:[],
    statusFlag:0,
    statuscode:0,
    brand_List: [],
    brand_List_Message: '',
    all_Brand_List: [],
    brandWiseItemList: []
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
    },
    clearStoreData: (state, action) => {
      state.brand_List = action.payload.brand_List
      state.brand_List_Message = action.payload.brand_List_Message  
      state.all_Brand_List = action.payload.all_Brand_List  
    }
  },
  extraReducers: builder => {
    builder.addCase(getBrandList.fulfilled, (state, action) => {
      state.brand_List = action.payload.brand_List
      state.brand_List_Message = action.payload.brand_List_Message
      state.all_Brand_List = action.payload.all_Brand_List
    }).addCase(saveData.fulfilled, (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.message = action.payload.message
    })
    .addCase(deleteData.fulfilled, (state, action) => {
      state.message = action.payload.message
     state.statusFlag = action.payload.statusFlag
    })
    .addCase(Itemgrouplist.fulfilled, (state, action) => {
      state.Itemgrouplist = action.payload.Itemgrouplist
      state.TotalItemGroup = action.payload.TotalItemGroup
      state.brandWiseItemList = action.payload.brandWiseItemList
      state.params = action.payload.params
    })
  }
})
export const {
  handleStatusFlag, clearStoreData
} = ItemgroupMasterSlice.actions
export default ItemgroupMasterSlice.reducer

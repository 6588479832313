// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants'


//  GET Common LIST

export const commonData = createAsyncThunk('EmbroiderPerformanceReport/commonData', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/report/embroiderPerformanceFilterListJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/report/embroiderPerformanceFilterList`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          params,
          allitemArray: response1.data.body && response1.data.body.allitemgroupArray ? response1.data.body.allitemgroupArray : [],
          EmbroiderArray: response1.data.body && response1.data.body.EmbroiderArray ? response1.data.body.EmbroiderArray : []
        }
      } else {
        return {
          allitemArray: response1.data.body ? response1.data.body.allitemgroupArray : [],
          EmbroiderArray: response1.data.body ? response1.data.body.EmbroiderArray : []
        }
      }
    } else {
      return {
        allitemArray: response.data.body ? response.data.body.allitemgroupArray : [],
        EmbroiderArray: response.data.body ? response.data.body.EmbroiderArray : []
      }
    }
  } catch (error) {
    return {
      allitemArray: [],
      EmbroiderArray: []
    }
  }
})

// PerformaceReport LIST

export const PerformaceReportlist = createAsyncThunk('EmbroiderPerformanceReport/PerformaceReportlist', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/report/embroiderJobPerformanceReportListJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
        params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/report/embroiderJobPerformanceReportList`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          params,
          PerformaceReportlist: response1.data.body && response1.data.body.PerformanceArray ? response1.data.body.PerformanceArray : [],
          ItemcategoryeList: response1.data.body && response1.data.body.ItemcategoryeArray ? response1.data.body.ItemcategoryeArray : [],
          TotalList: response1.data.body && response1.data.body.Total_Array ? response1.data.body.Total_Array : [],
          CompanyList: response1.data.body && response1.data.body.CompanyArray ? response1.data.body.CompanyArray : []
          
        }
      } else {
        return {
          PerformaceReportlist: response1.data.body ? response1.data.body.PerformanceArray : [],
          ItemcategoryeList: response1.data.body ? response1.data.body.ItemcategoryeArray : [],
          TotalList: response1.data.body ? response1.data.body.Total_Array : [],
          CompanyList: response1.data.body ? response1.data.body.CompanyArray : []
        }
      }
    } else {
      return {
        PerformaceReportlist: response.data.body ? response.data.body.PerformanceArray : [],
        ItemcategoryeList: response.data.body ? response.data.body.ItemcategoryeArray : [],
        TotalList: response.data.body ? response.data.body.Total_Array : [],
        CompanyList: response.data.body ? response.data.body.CompanyArray : []
      }
    }
  } catch (error) {
    return {
      PerformaceReportlist: [],
      ItemcategoryeList: [],
      TotalList: [],
      CompanyList: []
    }
  }
})

  //Embroider Performace Report LIST

  export const specificEmbroiderList = createAsyncThunk('EmbroiderPerformanceReport/specificEmbroiderList', async (params) => {
    try {
      const response = await axios.post(`${api.api_url}/report/specificEmbroiderJobListJwt`, params)
      if (response && response.data && response.data.body && response.data.body.token) {
          params['jwtToken'] = response.data.body.token
        const response1 = await axios.post(`${api.api_url}/report/specificEmbroiderJobList`, params)
        if (response1 && response1.data && response1.data.body) {
          return {
            params,
            SpecificEmbroiderArray: response1.data.body && response1.data.body.SpecificEmbroiderArray ? response1.data.body.SpecificEmbroiderArray : []
          }
        } else {
          return {
            SpecificEmbroiderArray: response1.data.body ? response1.data.body.SpecificEmbroiderArray : []
          }
        }
      } else {
        return {
            SpecificEmbroiderArray: response.data.body ? response.data.body.SpecificEmbroiderArray : []
        }
      }
    } catch (error) {
      return {
        SpecificEmbroiderArray: []
      }
    }
  })

  export const getBrandList = createAsyncThunk('FinishedGoodList/brandFilterListJwt', async params => {
    try {
    const response = await axios.post(`${api.api_url}/common/brandFilterListJwt`, params)
     if (response && response.data && response.data.body && response.data.body.token) {
     params['jwtToken'] = response.data.body.token
     const response1 = await axios.post(`${api.api_url}/common/brandFilterList`, params)
     if (response1 && response1.data && response1.data.body) {
      return {
        all_Brand_List: response1.data.body && response1.data.body.All_Brand_Array ? response1.data.body.All_Brand_Array : [],
        brand_List_Message:response1.data.body.message
      }
     } else {
      return {
        all_Brand_List: response1.data.body ? response1.data.body.All_Brand_Array : [],
        brand_List_Message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
      }
     }  
    } else {
      return {
        all_Brand_List: response.data.body ? response.data.body.All_Brand_Array : [],
        brand_List_Message: response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
      }
     }
    } catch (error) {
      return {
        all_Brand_List: [],
        brand_List_Message:'Server not reachable.Please try again later'
      }
    }
  })

export const EmbroiderPerformanceReportSlice = createSlice({
  name: 'EmbroiderPerformanceReport',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    PerformaceReportlist:[],
    ItemcategoryeList:[],
    TotalList:[],
    statuslist:[],
    statusFlag:0,
    statuscode:0,
    allitemArray:[],
    EmbroiderArray:[],
    SpecificEmbroiderArray:[],
    all_Brand_List: [],
    brand_List_Message:'',
    CompanyList: []
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
    }
  },
  extraReducers: builder => {
    builder.addCase(PerformaceReportlist.fulfilled, (state, action) => {
      state.PerformaceReportlist = action.payload.PerformaceReportlist
      state.ItemcategoryeList = action.payload.ItemcategoryeList
      state.TotalList = action.payload.TotalList
      state.params = action.payload.params
      state.CompanyList = action.payload.CompanyList
    })
    .addCase(commonData.fulfilled, (state, action) => {
      state.allitemArray = action.payload.allitemArray
      state.EmbroiderArray = action.payload.EmbroiderArray
      state.params = action.payload.params
    })
    .addCase(specificEmbroiderList.fulfilled, (state, action) => {
      state.SpecificEmbroiderArray = action.payload.SpecificEmbroiderArray
      state.params = action.payload.params
    })
    .addCase(getBrandList.fulfilled, (state, action) => {
      state.brand_List_Message = action.payload.brand_List_Message
      state.all_Brand_List = action.payload.all_Brand_List
    })
  }
})
export const {
  handleStatusFlag
} = EmbroiderPerformanceReportSlice.actions
export default EmbroiderPerformanceReportSlice.reducer

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants'


//  GET Common LIST
export const commonData = createAsyncThunk('ItemManagement/commonData', async (params) => {
  const response = await axios.post(`${api.api_url}/common/fetchcommonDataJwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/common/fetchcommonData`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      params,
      ColorArray: response1.data.body.ColorArray,
      // ItemArray: response1.data.body.DefItemArray,
      BrandArray: response1.data.body.BrandArray
    }
   } else {
    return {
      params,
      ColorArray: [],
      // ItemArray: [],
      BrandArray: []
    }
   } 
}
})

//Check DesignNo. Api
export const CheckDesigNoData = createAsyncThunk('ItemManagement/CheckDesigNoData', async params => {
  const response = await axios.post(`${api.api_url}/orderManagement/itemManagement/CheckDesignNojwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/orderManagement/itemManagement/CheckDesignNo`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      checkFlag:response1.data.body.checkFlag,
      message:response1.data.body.message
    }
   } 
  } else {
    return {
      checkFlag:response1.data.body.checkFlag,
      message:response1.data.body.message
    }
   }
})

//Save Data Api
export const SaveData = createAsyncThunk('ItemManagement/SaveData', async params => {
  try {
  const response = await axios.post(`${api.api_url}/orderManagement/itemManagement/saveItemManagementjwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/orderManagement/itemManagement/saveItemManagement`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      statusFlag:response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
      message:response1.data.body.message
    }
   } else {
    return {
      statusFlag:response1.data.body ? response1.data.body.statusFlag : 2,
      message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
    }
   } 
  } else {
    return {
      statusFlag:response.data.body ? response.data.body.statusFlag : 2,
      message:response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
    }
   }
  } catch (error) {
    return {
      statusFlag:2,
      message:'Server not reachable.Please try again later'     
    }    
  }
})

// Item Management LIST
export const ItemManagementListData = createAsyncThunk('ItemManagement/ItemManagementListData', async (params) => {
  const response = await axios.post(`${api.api_url}/orderManagement/itemManagement/itemManagementListjwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/orderManagement/itemManagement/itemManagementList`, params)
    if (response1 && response1.data && response1.data.body) {
    return {
      params,
      itemManagementlist: response1.data.body.ItemManagementlist,
      TotalItem: response1.data.body.TotalItem,
      SizeArray:response1.data.body.SizeArray,
      brandWiseTotalList: response1.data.body.brandWiseTotalList
    }
   } else {
    return {
    itemManagementlist:[],
    TotalItem: 0,
    SizeArray:[],
    brandWiseTotalList: []
    }
   } 
}
})

// Edit Item Management LIST
export const EditData = createAsyncThunk('ItemManagement/EditData', async (params) => {
  const response = await axios.post(`${api.api_url}/orderManagement/itemManagement/editItemListjwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/orderManagement/itemManagement/editItemList`, params)
    if (response1 && response1.data && response1.data.body) {
    return {
      params,
      EditSizeList:response1.data.body.EditSizeList,
      EditItemArray:response1.data.body.EditItemArray
    }
   } else {
   } 
}
})

//DeleteData Api
export const DeleteData = createAsyncThunk('ItemManagement/DeleteData', async params => {
  const response = await axios.post(`${api.api_url}/orderManagement/itemManagement/deleteItemManagementjwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/orderManagement/itemManagement/deleteItemManagement`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      statusFlag:response1.data.body.statusFlag,
      message:response1.data.body.message
    }
   } 
  } else {
    return {
      statusFlag:response1.data.body.statusFlag,
      message:response1.data.body.message
    }
   }
})

//DeleteData Api
export const CheckSingleDeleteData = createAsyncThunk('ItemManagement/CheckSingleDeleteData', async params => {
  const response = await axios.post(`${api.api_url}/orderManagement/itemManagement/checkItemExistTransactionjwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/orderManagement/itemManagement/checkItemExistTransaction`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      check_single_statusFlag:response1.data.body.statusFlag,
      check_single_message:response1.data.body.message
    }
   } 
  } else {
    return {
      check_single_statusFlag:response1.data.body.statusFlag,
      check_single_message:response1.data.body.message
    }
   }
})
//getItemCategoryBasedOnBrand Api
export const getItemCategoryBasedOnBrand = createAsyncThunk('ItemManagement/getItemCategoryBasedOnBrandJwt', async params => {
  const response = await axios.post(`${api.api_url}/orderManagement/itemManagement/getItemCategoryBasedOnBrandJwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/orderManagement/itemManagement/getItemCategoryBasedOnBrand`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      ItemArray:response1.data.body.DefItemArray
    }
   } 
  } else {
    return {
      ItemArray: []
    }
   }
})

export const ItemManagementSlice = createSlice({
  name: 'ItemManagement',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    BrandArray: [],
    ColorArray:[],
    statuslist:[],
    ItemArray:[],
    statusFlag:0,
    checkFlag:0,
    statuscode:0,
    itemManagementlist:[],
    TotalItem: 0,
    SizeArray:[],
    EditSizeList:[],
    EditItemArray:[],
    check_single_statusFlag:0,
    check_single_message:0,
    brandWiseTotalList: []
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.check_single_statusFlag = action.payload.check_single_statusFlag
      state.check_single_message = action.payload.check_single_message
    },
    handleCheckFlag: (state, action) => {
      state.checkFlag = action.payload.checkFlag
    },
    clearStoreData: (state, action) => {
      state.ItemArray = action.payload.ItemArray
    }
  },
  extraReducers: builder => {
    builder.addCase(commonData.fulfilled, (state, action) => {
      state.ColorArray = action.payload.ColorArray
      // state.ItemArray = action.payload.ItemArray
      state.params = action.payload.params
      state.BrandArray = action.payload.BrandArray 
    }).addCase(CheckDesigNoData.fulfilled, (state, action) => {
      state.checkFlag = action.payload.checkFlag
      state.message = action.payload.message
    })
    .addCase(SaveData.fulfilled, (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.message = action.payload.message
    })
    .addCase(ItemManagementListData.fulfilled, (state, action) => {
      state.itemManagementlist = action.payload.itemManagementlist
      state.TotalItem = action.payload.TotalItem
      state.SizeArray = action.payload.SizeArray
      state.params = action.payload.params
      state.brandWiseTotalList = action.payload.brandWiseTotalList
    })
    .addCase(EditData.fulfilled, (state, action) => {
      state.EditSizeList = action.payload.EditSizeList
      state.EditItemArray = action.payload.EditItemArray
      state.params = action.payload.params
    })
    .addCase(DeleteData.fulfilled, (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.message = action.payload.message
    })
    .addCase(CheckSingleDeleteData.fulfilled, (state, action) => {
      state.check_single_statusFlag = action.payload.check_single_statusFlag
      state.check_single_message = action.payload.check_single_message
    })
    .addCase(getItemCategoryBasedOnBrand.fulfilled, (state, action) => {
      state.ItemArray = action.payload.ItemArray
    })
  }
})
export const {
  handleStatusFlag, handleCheckFlag, clearStoreData
} = ItemManagementSlice.actions
export default ItemManagementSlice.reducer
